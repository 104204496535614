import React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Separator from "../separator"

const JoinIOD = () => {
  const { t } = useTranslation('joiniod');
  return (
    <section className="section section-lg mt-0 pt-0">
      <Container className="mb-5">
        <Card className="bg-gradient-warning shadow-lg border-0">
          <div className="p-5">
            <Row className="align-items-center">
              <Col lg={8}>
                <h3 className="text-white">{t("title")}</h3>
                <p className="lead text-white mt-3">{t("text")}</p>
              </Col>
              <div className="col-lg-3 ml-lg-auto">
                <a href="mailto:contact@iod.ai?subject=Information&body=Hello%2C%0A%0AI%20am%20interested%20in%20IOD%20and%20would%20like%20to%20know%20more%20about%20joining%20the%20team%20...%0A%0ACheers%2C%0A..." className="btn btn-lg btn-block btn-white">{t("call_to_action")}</a>
              </div>
            </Row>
          </div>
        </Card>
      </Container>
      <Separator fill="fill-default"/>
    </section>
  );
}

export default JoinIOD;
import React from "react"
import { Col } from "react-bootstrap"
import Img from "gatsby-image"
import { FaLinkedin } from "react-icons/fa"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const TeamProfile = props => {
  return (
    <Col md={6} lg={4} className="mb-5">
      <div className="px-4 text-center">
        <Img
          fixed={props.fixed}
          className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
        />
        <div className="pt-4">
          <h5 className="title">
            <span className="d-block mb-1">{props.name}</span>
            <small className="h6 text-muted">{props.title}</small>
          </h5>
          <div className="mt-3">
            <OutboundLink
              className={`btn btn-${props.variant} btn-icon-only rounded-circle`}
              href={props.linkedin}
              target="_blank"
            >
              <FaLinkedin />
            </OutboundLink>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default TeamProfile

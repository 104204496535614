import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { DiReact } from "react-icons/di";
import { FaUsers } from "react-icons/fa";
import { IoIosPlanet } from "react-icons/io";

import ServiceBox from "./ServiceBox"

const Services = () => {
  const { t } = useTranslation('services');
  return (
    <section className="section section-lg pt-lg-0 mt--200" id="services">
      <Container>
        <Row className="row justify-content-center">
          <Col lg={12}>
            <Row className="row-grid">
              <ServiceBox
                variant="primary"
                icon={<DiReact />}
                title={t("ai_title")}
                description={t("ai_description")}
                badges={t("ai_badges", { returnObjects: true }) || []}
                ctaLink={t("ai_cta_link")}
                ctaLabel={t("ai_cta_label")}
              />
              <ServiceBox
                variant="warning"
                icon={<FaUsers />}
                title={t("m&s_title")}
                description={t("m&s_description")}
                badges={t("m&s_badges", { returnObjects: true }) || []}
                ctaLink={t("m&s_cta_link")}
                ctaLabel={t("m&s_cta_label")}
              />
              <ServiceBox
                variant="success"
                icon={<IoIosPlanet/>}
                title={t("web&m_title")}
                description={t("web&m_description")}
                badges={t("web&m_badges", { returnObjects: true }) || []}
                ctaLink={t("web&m_cta_link")}
                ctaLabel={t("web&m_cta_label")}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Services

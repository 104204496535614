import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import TeamProfile from "./TeamProfile"
import Separator from "../separator";

const Team = () => {
  const { t } = useTranslation('team');
  const data = useStaticQuery(
    graphql`
      query {
        allProfilesJson {
          nodes {
            id
            name
            title
            picture
            linkedin
          }
        }
        allFile(
          filter: { relativePath: { regex: "profiles/.+(jpg|jpeg)/i" } }
        ) {
          nodes {
            relativePath
            childImageSharp {
              fixed(width: 200, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    `
  )

  return (
    <section className="section section-lg bg-secondary" id="team">
      <Container>
        <Row className="justify-content-center text-center mb-lg">
          <Col lg={8}>
            <h2 className="display-3">{t("title")}</h2>
            <p className="lead text-muted">{t("subtitle")}</p>
          </Col>
        </Row>
        <Row>
          {data.allProfilesJson.nodes.map((node, i) => {
            const img_node = data.allFile.nodes.find(
              e => e.relativePath === `profiles/${node.picture}`
            )
            return (
              <TeamProfile
                key={node.id}
                fixed={img_node.childImageSharp.fixed}
                name={node.name}
                title={node.title}
                linkedin={node.linkedin}
                variant={['primary', 'success', 'warning', 'info'][i % 4]}
              />
            )
          })}
        </Row>
        <Separator fill="fill-white"/>
      </Container>
    </section>
  )
}

export default Team

import React from "react"
import withI18next from "../components/withI18next";

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/sections/Hero"
import Services from "../components/sections/Services"
import Howwework from "../components/sections/Howwework"
import Team from "../components/sections/Team"
import Bizdev from "../components/sections/Bizdev"
import JoinIOD from "../components/sections/JoinIOD"

import browserRedirect from "../helpers/browserRedirect";

const IndexPage = ({ pageContext: { locale, originalPath }, t }) => {
  
  browserRedirect(locale);

  return (
    <>
      <SEO title={t("index_title")} lang={locale} description={t("meta_description")}/>
      
      <Layout originalPath={originalPath}>
        <Hero withText/>
        <Services />
        <Howwework />
        <Team />
        <Bizdev />
        <JoinIOD />
      </Layout>
    </>
  );
};

export default withI18next({ ns: "common" })(IndexPage);

import React from "react"
import { Col, Card, Badge } from "react-bootstrap"
import {IconContext} from "react-icons"

const ServiceBox = (props) => {
  const badges = Array.isArray(props.badges) ? props.badges : [];
  return (
    <IconContext.Provider value={{ style: { verticalAlign: 'middle'} }}>
      <Col lg={4}>
        <Card className="card-lift--hover shadow border-0 h-100">
          <Card.Body className="d-flex flex-column py-5">
            <div className={`icon icon-shape icon-shape-${props.variant} rounded-circle mb-4`}>
              {props.icon}
            </div>
            <h6 className={`text-${props.variant} text-uppercase`}> {props.title} </h6>
            <p className="description mt-3 flex-grow-1"> {props.description} </p>
            <div>
              {badges.map((label, i) => (<Badge key={i} pill variant={props.variant}>{label}</Badge>))}
            </div>
            <div>
              <a href={props.ctaLink} className={`btn btn-${props.variant} mt-4`}>{props.ctaLabel}</a>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </IconContext.Provider>
  );
}

export default ServiceBox;
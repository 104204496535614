import React from "react"
import {
  Container,
  Row,
  Col,
  TabContainer,
  Tab,
  Nav,
  Card,
} from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { IoIosSettings } from "react-icons/io";
import { FaPhone, FaForward, FaCompressArrowsAlt, FaThumbsUp } from "react-icons/fa";

import artboardSVG from "../../images/artboard-25.svg"

const Howwework = () => {
  const { t } = useTranslation('howwework');
  return (
    <section className="section section-lg" id="howwework">
      <Container>
        <Row className="row-grid align-items-center">
          <Col lg={6} className="order-lg-2">
            <img
              src={artboardSVG}
              className="img-fluid floating"
              alt={t("artboard_img_alt")}
            />
          </Col>
          <Col lg={6} className="order-lg-1">
            <div className="pr-lg-5">
              <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                <IoIosSettings/>
              </div>
              <h3>{t("title")}</h3>
              <p>{t("description")}</p>
              <div className="mt-4 mb-3">
                <small className="text-uppercase font-weight-bold">
                  {t("process_title")}
                </small>
              </div>
              <TabContainer id="how-we-work-tabs" defaultActiveKey="Contact">
                <Nav variant="pills" className="d-flex flex-nowrap mb-3">
                  <Nav.Item className="flex-fill text-center">
                    <Nav.Link eventKey="Contact" className="px-4">
                      <FaPhone className="mr-2" /> {t("contact_step_title")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="flex-fill text-center">
                    <Nav.Link eventKey="Matching" className="px-2">
                      <FaCompressArrowsAlt className="mr-2" /> {t("matching_step_title")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="flex-fill text-center">
                    <Nav.Link eventKey="Execution" className="px-4">
                      <FaForward className="mr-2" /> {t("execution_step_title")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Card className="shadow">
                  <Card.Body>
                    <Tab.Content>
                      <Tab.Pane eventKey="Contact" className="fade">
                        <p className="description">{t("contact_step_description")}</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Matching" className="fade">
                        <p className="description">{t("matching_step_description")}</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Execution" className="fade">
                        <p className="description">{t("execution_step_description")}</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </TabContainer>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Container className="mb-5">
            <Card className="bg-gradient-success shadow-lg border-0">
              <div className="p-3">
                <Row className="align-items-center">
                  <Col xs={12}>
                    <p className="lead text-white mt-3">
                      <FaThumbsUp className="ml-2 mr-3" style={{marginBottom: 4}}/>
                      <span dangerouslySetInnerHTML={{__html: t("lean_selling_point")}}></span>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </Row>
      </Container>
    </section>
  )
}

export default Howwework
